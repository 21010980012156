import { Link } from "gatsby";
import React, { ReactNode } from "react";
import styled from "styled-components";
import LogoSvg from "@/assets/logo.svg";
import IndexBg from "@/assets/index-bg.jpg";

export interface IProps {
	children: ReactNode;
	navbar: boolean;
}

const Container = styled.section`
	max-width: 1536px;
`;

const Footer = styled.div``;

const FooterText = styled.span``;

interface IMainContainerProps {
	bgImageUrl?: string; 
}
const MainContainer = styled.div<IMainContainerProps>`
	background-image: url(${IndexBg});
	background-repeat: no-repeat;
	background-size: cover;
	background-position: 50%;
`

export function MainLayout(props: IProps) {
	return (
		<MainContainer className="h-screen flex flex-col w-full bg-white">
			{props.navbar && (
				<nav className="flex w-full items-center justify-between px-6 md:px-20 py-10 h-16 z-20 inset-0 mb-4">
					<Link to="/" className="font-bold text-xl">
						<img src={LogoSvg} alt="Realpublik logo" width="180px" />
					</Link>
				</nav>
			)}
			<Container className="container mx-auto flex-grow px-6 md:px-20">{props.children}</Container>
			<Footer className="container mx-auto flex pb-4 justify-between mt-8 px-6">
				<FooterText>© 2021 Todos los derechos reservados</FooterText>
				<Link to="/why">¿Por qué Realpublik Management?</Link>
			</Footer>
		</MainContainer>
	);
}
