import { Link, navigate } from "gatsby";
import React, { ReactNode } from "react";
import styled from "styled-components";
import LogoWhiteSvg from "@/assets/logo-white.svg";
import LogoSvg from "@/assets/logo.svg";
import { AuthApi } from "@/components";
import IndexBg from "@/assets/index-bg.jpg";
import { ForceLogin } from "@/components/Auth/components/ForceLogin";

interface IProps {
	children: ReactNode;
	navbar: boolean;
}

const Container = styled.section`
	max-width: 1480px;
`;

const Footer = styled.div``;

const FooterText = styled.span``;

const MainContainer = styled.div`
	min-height: 100vh;
`;

const NavGame = styled.nav`
	background-image: url(${IndexBg});
	background-repeat: no-repeat;
	background-size: cover;
	background-position: 50% 40%;
	max-width: 1480px;
`;

export function GameLayout(props: IProps) {
	return (
		<ForceLogin>
			<MainContainer className="flex flex-col w-full bg-gray-50">
				<NavGame className="flex w-full items-center justify-between container mx-auto p-2 lg:p-10 h-16 z-20 inset-0 text-white mb-8">
					<Link to="/" className="font-bold text-xl bg-white p-2 bg-opacity-75">
						<img src={LogoSvg} alt="Realpublik logo" className="w-32 lg:w-40" />
					</Link>
					<a
						className="text-black cursor-pointer"
						onClick={async () => {
							await AuthApi.signOut();
							navigate("/");
						}}>
						Cerrar Sesión
					</a>
				</NavGame>
				<Container className="container mx-auto flex-grow text-black relative px-2 2xl:px-0">{props.children}</Container>
				<Footer className="container mx-auto flex py-4 justify-between text-black">
					<FooterText>© 2021 Todos los derechos reservados</FooterText>
					{/* <Link to="/why">¿Por qué Realpublik Management?</Link> */}
				</Footer>
			</MainContainer>
		</ForceLogin>
	);
}
