import { IEngineAction, IEngineActionEffect, IEngineVarEffect, IGameConfig, IGamerAccountGame, IGameRound, IListItem } from "@/api";
import { PrimaryButton } from "@/components";
import React from "react";
import styled from "styled-components";
import { format } from "date-fns";
import { MultilateralView } from "@/pages/game";
export interface IActionCardProps {
	action: IEngineAction;
	type: "actor" | "affected" | "used" | "actor_used" | "affected_used";
	gameCongif: IGameConfig;
	defaultGame: IGamerAccountGame;
	currentRound?: IGameRound;
	useAction?: (gameId: string, roundId: string, actorId: string, actionId: string) => void;
	shortCards?: boolean;
}
const ActionCardWrapper = styled.div<Partial<IActionCardProps>>`
	height: ${(props) => {
		return props.shortCards ? "250px" : "450px";
	}};
`;

export function GameActionCard(props: IActionCardProps) {
	const { action } = props;
	const showUseButton = props.defaultGame && props.type === "actor" && props.gameCongif.status === "Started" && !action.isUsed;
	return (
		<ActionCardWrapper shortCards={props.shortCards} className="bg-white text-black rounded px-4 py-4 text-center flex flex-col overflow-auto border shadow-2xl">
			<div className={`${props.type.startsWith("actor") ? "bg-black" : "bg-yellow-600"} text-white font-bold self-center rounded p-1 text-xs flex items-center justify-center mb-4`}>{`${action.actionId}${
				action.executedBy?.includes(action.executionActorId) ? "✅" : ""
			}`}</div>
			{action.isMultilateral && (
				<MultilateralView
					items={action.secondaryExecutionActors.map((sea) => {
						return { value: sea.value, isUsed: action.executedBy?.includes(sea.value) };
					})}
				/>
			)}
			<h3 className="font-bold text-xl">{action.name}</h3>
			<p>{action.description}</p>
			<h3 className="font-bold mt-2">Efectos</h3>
			{action.effects.map((effect: IEngineActionEffect, index: number) => {
				return (
					<div className="border-b-2 text-sm text-left py-4 flex flex-col" key={index}>
						<div className="bg-white text-black border-2 border-black font-bold self-center rounded-full h-6 w-6 flex items-center justify-center mb-2">{index + 1}</div>
						{effect.description}
						<p className="font-bold mt-2">Afectados</p>
						<div className="flex space-x-2 mt-2">
							{effect.affectedActors.map((actor: IListItem, index: number) => {
								return (
									<div className="bg-gray-100 p-2 rounded text-xs font-bold" key={index}>
										{actor.text}
									</div>
								);
							})}
						</div>
						{effect.actorVarEffects && (
							<div className="mt-2 flex flex-col space-y-2">
								{effect.actorVarEffects.map((affectedVar: IEngineVarEffect, index: number) => {
									return (
										<div key={index} className="text-xs flex items-center space-x-2">
											{affectedVar.value < 0 && (
												<svg style={{ width: "20px" }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="orange">
													<path
														fillRule="evenodd"
														d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-11a1 1 0 10-2 0v3.586L7.707 9.293a1 1 0 00-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L11 10.586V7z"
														clipRule="evenodd"
													/>
												</svg>
											)}
											{affectedVar.value > 0 && (
												<svg style={{ width: "20px" }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="green">
													<path
														fillRule="evenodd"
														d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-8.707l-3-3a1 1 0 00-1.414 0l-3 3a1 1 0 001.414 1.414L9 9.414V13a1 1 0 102 0V9.414l1.293 1.293a1 1 0 001.414-1.414z"
														clipRule="evenodd"
													/>
												</svg>
											)}
											<span>{affectedVar.value}</span>
											<span className="font-bold">{affectedVar.name}</span>
										</div>
									);
								})}
							</div>
						)}
						{effect.globalVarEffects && (
							<div className="mt-2 flex flex-col space-y-2">
								{effect.globalVarEffects.map((affectedVar: IEngineVarEffect, index: number) => {
									return (
										<div key={index} className="text-xs flex items-center space-x-2">
											{affectedVar.value < 0 && (
												<svg style={{ width: "20px" }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="orange">
													<path
														fillRule="evenodd"
														d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-11a1 1 0 10-2 0v3.586L7.707 9.293a1 1 0 00-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L11 10.586V7z"
														clipRule="evenodd"
													/>
												</svg>
											)}
											{affectedVar.value > 0 && (
												<svg style={{ width: "20px" }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="green">
													<path
														fillRule="evenodd"
														d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-8.707l-3-3a1 1 0 00-1.414 0l-3 3a1 1 0 001.414 1.414L9 9.414V13a1 1 0 102 0V9.414l1.293 1.293a1 1 0 001.414-1.414z"
														clipRule="evenodd"
													/>
												</svg>
											)}
											<span>{affectedVar.value}</span>
											<span className="font-bold">{affectedVar.name}</span>
										</div>
									);
								})}
							</div>
						)}
					</div>
				);
			})}
			{showUseButton && (
				<PrimaryButton
					className="mt-4"
					onClick={async () => {
						if (props.useAction) {
							props.useAction(props.defaultGame.id, props.currentRound.roundId, props.defaultGame.actorId, props.action.actionId);
						}
					}}>
					Usar
				</PrimaryButton>
			)}
			{action.isUsed && action.usedAt && <div className="mt-4 font-bold">Usada el {format(new Date(action.usedAt), "dd/MM/yyyy hh:mm aa")}</div>}
			<div className="py-4"></div>
		</ActionCardWrapper>
	);
}
