import { IEngineAction, IAction, IGameConfig, IGamerAccountGame, IGameRound, IEngineActionEffect, IListItem } from "@/api";
import { ActionCard } from "@/pages/game";
import React from "react";
import { GameActionCard } from "./GameActionCard";
import { GameViews } from "./types";

interface Props {
	gamerActions: IAction[];
	gamerEngineActions: IEngineAction[];
	gameConfig: IGameConfig;
	currentRound: IGameRound;
	defaultGame: IGamerAccountGame;
	onUseAction: (gameId: string, roundId: string, actorId: string, actionId: string) => Promise<void>;
	currentView: GameViews;
}

export function GameActorActionsView({ gamerActions, gamerEngineActions, gameConfig, currentRound, defaultGame, onUseAction, currentView }: Props) {
	if (!gameConfig) return null;
	let displayActions: IEngineAction[];
	if (gamerEngineActions) displayActions = [...gamerEngineActions];
	else if (gamerActions)
		displayActions = gamerActions.map((action) => ({
			actionId: action.id,
			description: action.description,
			effects: action.effects.map(
				(ae) =>
					({
						id: ae.id,
						description: ae.name,
						affectedActors: ae.affectedActors.map((s) => ({
							tag: s,
							text: s,
							value: s,
						})),
						actorVarEffects: ae.affectedVariables.map((v) => ({
							id: v.alias,
							name: v.alias,
							value: v.value,
						})),
						globalVarEffects: ae.globalAffectedVariables.map((v) => ({
							id: v.alias,
							name: v.alias,
							value: v.value,
						})),
					} as IEngineActionEffect)
			),
			executionActorId: action.executionActorId,
			executionActorName: action.executionActorId,
			isMultilateral: action.isMultilateral,
			isUsed: false,
			name: action.name,
			secondaryExecutionActors: action.secondaryActorIds.map((sai) => ({ tag: sai, text: sai, value: sai } as IListItem)),
		}));
	if (!displayActions) return null;
	// const gameStarted = currentRound && gameConfig.status === "Started";
	const isUnilateralView = currentView === "actions_actor" || currentView === "actions_actor:uni";

	displayActions = displayActions.filter((da) => da.isMultilateral !== isUnilateralView);

	return (
		<div className="grid grid-flow-row grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-8">
			{displayActions.map((action: IEngineAction, index: number) => {
				return <GameActionCard useAction={onUseAction} action={action} key={index} type="actor" gameCongif={gameConfig} defaultGame={defaultGame} currentRound={currentRound} />;
			})}
		</div>
		// <>
		// 	{gamerActions && (gameConfig.status !== "Started" || (gameConfig.status === "Started" && !currentRound)) && (
		// 		<div className="grid grid-flow-row grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-8">
		// 			{gamerActions.map((action: IAction, index: number) => {
		// 				return <ActionCard action={action} key={index} type="actor" gameCongif={gameConfig} defaultGame={defaultGame} currentRound={currentRound} />;
		// 			})}
		// 		</div>
		// 	)}
		// 	{gamerEngineActions && currentRound && gameConfig.status === "Started" && (
		// 		<div className="grid grid-flow-row grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-8">
		// 			{gamerEngineActions.map((action: IEngineAction, index: number) => {
		// 				return <GameActionCard useAction={onUseAction} action={action} key={index} type="actor" gameCongif={gameConfig} defaultGame={defaultGame} currentRound={currentRound} />;
		// 			})}
		// 		</div>
		// 	)}
		// </>
	);
}
