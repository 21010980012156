import React, { useEffect } from "react";
import { navigate } from "gatsby";
import { useAuthContext } from "../hooks";

interface IForceLoginProps {
	children: React.ReactNode;
	loginPath?: string;
	returnHere?: boolean;
}

/**
 * Wrapper that will force a user to log in. If not
 * logged in it will redirect to the `loginPath` prop.
 *
 * This should be wrapped with the AuthProvider component
 * for it to work
 */
export function ForceLogin(props: IForceLoginProps) {
	const { isLoggedIn } = useAuthContext();
	const defaultLoginTo = "/";

	// Get out of here if not logged in
	useEffect(() => {
		if (!isLoggedIn) {
			// Determine the returnTo url
			const currentUrl = new URL(window.location.href);
			const returnUrl = encodeURIComponent(`${currentUrl.pathname}${currentUrl.search}`);
			// console.info

			// Navigate to the login page with the returnTo url so that it
			// comes back to this page
			if (props.returnHere !== false) {
				navigate(`${props.loginPath || defaultLoginTo}?returnTo=${returnUrl}`);
			} else {
				navigate(`${props.loginPath || defaultLoginTo}`);
			}
		}
	}, [isLoggedIn]);

	// Render the view
	if (isLoggedIn) {
		return <React.Fragment>{props.children}</React.Fragment>;
	}
	return null;
}
