import {
	getActions,
	getActionsAffected,
	getEngineActions,
	getEngineActionsAffect,
	getGameActors,
	getGameConfig,
	getGameCurrentRound,
	getGamerAccount,
	getUsedActions,
	IAction,
	IEffect,
	IEngineAction,
	IGameActor,
	IGameConfig,
	IGamerAccount,
	IGamerAccountGame,
	IGameRound,
	IRoundUsedActions,
	IVar,
	useAction,
} from "@/api";
import { Loader, PrimaryButton, SEO, useAuthContext } from "@/components";
import { GameLayout } from "@/layouts";
import { GameActionCard, GameActorActionsView, GameNavBar, GameNavBarAction, GameViews, LoadingMessages } from "@/pageComponents";
import React, { ChangeEvent } from "react";
import { format } from "date-fns";
import styled from "styled-components";
import { MainTitleWrapper } from "@/components/typography";
import { Link } from "gatsby";
import { getSessionStorage, setSessionStorage } from "@/helpers";
import { GAME_CONFIG_SS_KEY } from "@/helpers/constants";

function Game() {
	const { userInfo } = useAuthContext();
	const [zazarSelectedActorId, setZazarSelectedActorId] = React.useState<string>();
	const [isLoading, setIsLoading] = React.useState<boolean>(true);
	const [loadingMessage, setLoadingMessage] = React.useState<LoadingMessages>("Loading Profile");
	const [gamerAccount, setGamerAccount] = React.useState<IGamerAccount>();
	const [gameConfig, setGameConfig] = React.useState<IGameConfig>();
	const [defaultGame, setDefaultGame] = React.useState<IGamerAccountGame>();
	const [currentRound, setCurrentRound] = React.useState<IGameRound>();
	const [gamerActions, setGamerActions] = React.useState<IAction[]>();
	const [gamerActionsAffected, setGamerActionsAffected] = React.useState<IAction[]>();
	const [gamerEngineActions, setGamerEngineActions] = React.useState<IEngineAction[]>();
	const [gamerEngineActionsAffected, setGamerEngineActionsAffected] = React.useState<IEngineAction[]>();
	const [gamerUsedActions, setGamerUsedActions] = React.useState<IRoundUsedActions[]>();
	const [currentView, setCurrentView] = React.useState<GameViews>("actions_actor");
	const [actors, setActors] = React.useState<IGameActor[]>();
	const [selectedGamerActions, setSelectedGamerActions] = React.useState<IAction[]>();
	const [selectedGamerActionsAffected, setSelectedGamerActionsAffected] = React.useState<IAction[]>();
	const onGameNavBarAction = (action: GameNavBarAction) => {
		if (action === "show_used_actions") showUsedActions();
		else if (action === "zazar_show_all_actions") {
			setCurrentView("admin_actions_view");
			loadGameActors();
		}
	};
	const loadGameActors = async () => {
		if (!defaultGame || defaultGame.actorId !== "Zazar") {
			return;
		}
		setIsLoading(true);
		setLoadingMessage("Loading Actors");
		const actorsResult = await getGameActors(defaultGame.id);
		const firstActor = actorsResult[0];
		setLoadingMessage("Loading Actions");
		setActors(actorsResult);
		setSelectedGamerActions(await getActions(firstActor.id, defaultGame.id));
		setSelectedGamerActionsAffected(await getActionsAffected(firstActor.id, defaultGame.id));
		setZazarSelectedActorId(firstActor.id);
		setIsLoading(false);
	};
	const loadGamerAccount = async () => {
		const gamerAccountResponse = await getGamerAccount(userInfo.username);
		setGamerAccount(gamerAccountResponse);
	};
	const loadActions = async () => {
		const actions = await getActions(defaultGame.actorId, defaultGame.id);
		const actionsAffected = await getActionsAffected(defaultGame.actorId, defaultGame.id);
		setGamerActions(actions);
		setGamerActionsAffected(actionsAffected);
	};
	const loadEngineActions = async () => {
		const actions = await getEngineActions(defaultGame.actorId, defaultGame.id, currentRound.roundId);
		const actionsAffected = await getEngineActionsAffect(defaultGame.actorId, defaultGame.id, currentRound.roundId);
		setGamerEngineActions(actions);
		setGamerEngineActionsAffected(actionsAffected);
	};
	const loadGameConfig = async () => {
		const gameConfigResponse = await getGameConfig(defaultGame.id);
		setGameConfig(gameConfigResponse);
	};
	const loadCurrentRound = async () => {
		const getCurrenRound = await getGameCurrentRound(defaultGame.id);
		setCurrentRound(getCurrenRound);
	};
	React.useEffect(() => {
		if (!userInfo || !userInfo.username) {
			return;
		}
		loadGamerAccount();
	}, [userInfo]);
	React.useEffect(() => {
		if (!gamerAccount) {
			return;
		}
		// TODO: fix this!
		if (gamerAccount.role && gamerAccount.role === "zazar") {
			const sessionGameId = getSessionStorage("zazar-game-id", "");
			setDefaultGame({
				actorId: "Zazar",
				gameActorRole: 1,
				id: sessionGameId,
			});
		} else {
			// TODO: we want to support multiple games per account
			const defaultGame = gamerAccount.games[0];
			setDefaultGame(defaultGame);
		}
	}, [gamerAccount]);
	React.useEffect(() => {
		if (!gameConfig) {
			return;
		}
		// Store the game config in the browser's local storage
		setSessionStorage(GAME_CONFIG_SS_KEY, gameConfig);
		if (gameConfig.status === "Started") {
			// Load the rest of the data
			setLoadingMessage("Loading Round");
			loadCurrentRound();
			return;
		} else {
			setLoadingMessage("Loading Actions");
			loadActions();
		}
	}, [gameConfig]);
	React.useEffect(() => {
		if (!defaultGame) {
			return;
		}
		setLoadingMessage("Loading Game Info");
		loadGameConfig();
	}, [defaultGame]);
	React.useEffect(() => {
		if (!gamerActionsAffected || !gamerActions) {
			return;
		}
		setIsLoading(false);
	}, [gamerActions, gamerActionsAffected]);
	React.useEffect(() => {
		if (!currentRound && gameConfig && gameConfig.status === "Started") {
			setLoadingMessage("Loading Actions");
			loadActions();
			return;
		} else if (!currentRound) {
			return;
		}
		setLoadingMessage("Loading Actions");
		loadEngineActions();
	}, [currentRound, gameConfig]);
	React.useEffect(() => {
		if (!gamerEngineActions || !gamerEngineActionsAffected) {
			return;
		}
		setIsLoading(false);
	}, [gamerEngineActions, gamerEngineActionsAffected]);
	React.useEffect(() => {
		if (!gamerUsedActions) {
			return;
		}
		setIsLoading(false);
	}, [gamerUsedActions]);

	const showUsedActions = async () => {
		setIsLoading(true);
		setLoadingMessage("Loading Board");
		// const usedActions = defaultGame.actorId === "Zazar" ? await getUsedActions(defaultGame.id) : await getUsedActions(defaultGame.id, defaultGame.actorId);
		const usedActions = await getUsedActions(defaultGame.id);
		setGamerUsedActions(usedActions);
		setCurrentView("board");
	};

	const onZazarSelectChange = async (event: ChangeEvent<HTMLSelectElement>) => {
		const selectedActorId = event.currentTarget.value;
		setIsLoading(true);
		setZazarSelectedActorId(selectedActorId);
		setLoadingMessage("Loading Actions");
		setSelectedGamerActions(await getActions(selectedActorId, defaultGame.id));
		setSelectedGamerActionsAffected(await getActionsAffected(selectedActorId, defaultGame.id));
		setIsLoading(false);
	};

	const onUseAction = async (gameId: string, roundId: string, actorId: string, actionId: string) => {
		setIsLoading(true);
		setLoadingMessage("Saving");
		try {
			await useAction(gameId, roundId, actorId, actionId);
		} catch (error) {
			console.info({ ...error });
		}
		setLoadingMessage("Loading Actions");
		await loadEngineActions();
	};
	return (
		<GameLayout navbar={true}>
			<SEO title="Game" />
			{isLoading && <Loader message={loadingMessage} />}
			<div className="flex flex-row justify-end pb-4">
				<Link type="button" className="hover:underline cursor-pointer" to="/dashboard">
					📊 Ver el Dashboard
				</Link>
				{defaultGame && userInfo?.email?.includes("+zazar@") && (
					<Link type="button" className="hover:underline cursor-pointer ml-2" to="/game-picker">
						Seleccionar otro juego
					</Link>
				)}
				{defaultGame && userInfo?.email?.includes("+zazar@") && (
					<Link type="button" className="hover:underline cursor-pointer ml-2" to="/team-management">
						Administración de equipos
					</Link>
				)}
			</div>
			{gameConfig && gamerAccount && <MainTitle currentView={currentView} gameConfig={gameConfig} gamerAccount={gamerAccount} />}
			<GameNavBar currentView={currentView} defaultGame={defaultGame} gameConfig={gameConfig} setCurrentView={setCurrentView} onGameNavBarAction={onGameNavBarAction} />
			<div className="my-12"></div>
			{currentView === "admin_actions_view" && (
				<div className="flex flex-col">
					{actors && (
						<select onChange={onZazarSelectChange}>
							{actors.map((actor, index) => {
								return (
									<option key={index} value={actor.id}>
										{actor.id}
									</option>
								);
							})}
						</select>
					)}
					<div className="flex flex-col my-4">
						<h2 className="text-2xl font-bold">Acciones que puede usar el actor</h2>
						{selectedGamerActions && (
							<div className="grid grid-flow-row grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-8">
								{selectedGamerActions.map((action: IAction, index: number) => {
									return <ActionCard overrideActorId={zazarSelectedActorId} useAction={onUseAction} action={action} key={index} type="actor" gameCongif={gameConfig} defaultGame={defaultGame} currentRound={currentRound} />;
								})}
							</div>
						)}
					</div>
					<div className="flex flex-col my-4">
						<h2 className="text-2xl font-bold">Acciones que afectan al actor</h2>
						{selectedGamerActionsAffected && (
							<div className="grid grid-flow-row grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-8">
								{selectedGamerActionsAffected.map((action: IAction, index: number) => {
									return (
										<ActionCard overrideActorId={zazarSelectedActorId} useAction={onUseAction} action={action} key={index} type="affected" gameCongif={gameConfig} defaultGame={defaultGame} currentRound={currentRound} />
									);
								})}
							</div>
						)}
					</div>
				</div>
			)}
			{currentView === "game_info" && defaultGame && gameConfig && <InfoCard actorId={defaultGame.actorId} gameConfig={gameConfig} currentRound={currentRound} />}
			{currentView.startsWith("actions_actor") && (
				<GameActorActionsView currentView={currentView} currentRound={currentRound} defaultGame={defaultGame} gameConfig={gameConfig} gamerActions={gamerActions} gamerEngineActions={gamerEngineActions} onUseAction={onUseAction} />
			)}
			{currentView === "actions_affected" && gamerActionsAffected && gameConfig && (gameConfig.status !== "Started" || (gameConfig.status === "Started" && !currentRound)) && (
				<div className="grid grid-flow-row grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-8">
					{gamerActionsAffected.map((action: IAction, index: number) => {
						return <ActionCard action={action} key={index} type="affected" gameCongif={gameConfig} defaultGame={defaultGame} currentRound={currentRound} />;
					})}
				</div>
			)}
			{currentView === "actions_affected" && gamerEngineActionsAffected && gameConfig && currentRound && gameConfig.status === "Started" && (
				<div className="grid grid-flow-row grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-8">
					{gamerEngineActionsAffected.map((action: IEngineAction, index: number) => {
						return <GameActionCard action={action} key={index} type="affected" gameCongif={gameConfig} defaultGame={defaultGame} currentRound={currentRound} />;
					})}
				</div>
			)}
			{currentView === "board" &&
				gamerUsedActions &&
				gamerUsedActions.map((roundUsedAction, index) => {
					return (
						<div key={index} className="divide-y my-4">
							<h2 className="text-2xl font-bold">{roundUsedAction.roundId}</h2>
							<div className="grid grid-cols-1 gap-2">
								<div>
									<h2 className="font-bold mb-4">Acciones Usadas</h2>
									<div className="grid grid-flow-row grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-8">
										{roundUsedAction.actions.map((action: IEngineAction, index: number) => {
											return <GameActionCard action={action} key={index} type="actor_used" gameCongif={gameConfig} defaultGame={defaultGame} currentRound={currentRound} />;
										})}
									</div>
								</div>
								{roundUsedAction.actionsAffected && (
									<div>
										<h2 className="font-bold mb-4">Acciones usadas que te afectan</h2>
										<div className="grid grid-cols-1 md:grid-cols-4 gap-4">
											{roundUsedAction.actionsAffected.map((action: IEngineAction, index: number) => {
												return <GameActionCard action={action} key={index} type="affected_used" gameCongif={gameConfig} defaultGame={defaultGame} currentRound={currentRound} />;
											})}
										</div>
									</div>
								)}
							</div>
						</div>
					);
				})}
		</GameLayout>
	);
}

interface IMainTitleProps {
	gameConfig: IGameConfig;
	gamerAccount: IGamerAccount;
	currentView: GameViews;
}

function MainTitle(props: IMainTitleProps) {
	if (props.currentView === "board") {
		return <MainTitleWrapper>{props.gamerAccount.names}, estas son las acciones que has realizado durante los últimos años</MainTitleWrapper>;
	}
	if (props.currentView === "game_info") {
		return <MainTitleWrapper>{props.gamerAccount.names}, esta es la información de tu juego</MainTitleWrapper>;
	}
	if (props.gameConfig.status === "Started") {
		return <MainTitleWrapper>Hola {props.gamerAccount.names}, utiliza tus acciones</MainTitleWrapper>;
	}
	return (
		<>
			<MainTitleWrapper>Hola {props.gamerAccount.names}, explora tus acciones</MainTitleWrapper>
			<h2 className="text-xl text-center my-2">
				El juego <strong>aún no empieza</strong>. Mientras tanto, puedes explorar las acciones que puedes usar y también las que te afectan.
			</h2>
		</>
	);
}

const ActionCardWrapper = styled.div`
	height: 450px;
`;

interface IActionCardProps {
	action: IAction;
	type: "actor" | "affected";
	gameCongif: IGameConfig;
	defaultGame: IGamerAccountGame;
	currentRound?: IGameRound;
	overrideActorId?: string;
	useAction?: (gameId: string, roundId: string, actorId: string, actionId: string) => Promise<void>;
}

type MultilateralViewItem = {
	value: string;
	isUsed: boolean;
};

export function MultilateralView({ items }: { items: MultilateralViewItem[] }) {
	const filteredSecondaryExecutionActors = items.filter((i) => i.value.toLowerCase() !== "zazar");
	if (!filteredSecondaryExecutionActors.length) return null;
	return (
		<div className="flex flex-col text-center my-2 w-100">
			<div className="font-bold text-sm">Multilateral</div>
			<div className="flex justify-center flex-wrap">
				{filteredSecondaryExecutionActors.map((item, index) => {
					return (
						<div key={index} className={`bg-gray-200 text-black font-bold self-center rounded p-1 text-xs justify-center m-2`}>
							{`${item.value}${item.isUsed ? "✅" : ""}`}
						</div>
					);
				})}
			</div>
		</div>
	);
}

export function ActionCard(props: IActionCardProps) {
	const { action } = props;
	const [usedAt, setUsedAt] = React.useState<string>();
	const showUseButton = !usedAt && props.defaultGame && props.type === "actor" && props.gameCongif.status === "Started";
	return (
		<ActionCardWrapper className="bg-white text-black rounded px-4 py-4 text-center flex flex-col overflow-auto border shadow-2xl">
			<div className={`${props.type === "actor" ? "bg-black" : "bg-yellow-600"} text-white font-bold self-center rounded p-1 text-xs flex items-center justify-center mb-4`}>{action.id}</div>
			{action.isMultilateral && (
				<MultilateralView
					items={action.secondaryActorIds.map((item) => {
						return { isUsed: false, value: item };
					})}
				/>
			)}
			<h3 className="font-bold text-xl">{action.name}</h3>
			<p>{action.description}</p>
			<h3 className="font-bold mt-2">Efectos</h3>
			{action.effects.map((effect: IEffect, index: number) => {
				return (
					<div className="border-b-2 text-sm text-left py-4 flex flex-col" key={index}>
						<div className="bg-white text-black border-2 border-black font-bold self-center rounded-full h-6 w-6 flex items-center justify-center mb-2">{index + 1}</div>
						{effect.name}
						<p className="font-bold mt-2">Afectados</p>
						<div className="flex space-x-2 mt-2">
							{effect.affectedActors.map((actor: string, index: number) => {
								return (
									<div className="bg-gray-100 p-2 rounded text-xs font-bold" key={index}>
										{actor}
									</div>
								);
							})}
						</div>
						{effect.affectedVariables && (
							<div className="mt-2 flex flex-col space-y-2">
								{effect.affectedVariables.map((affectedVar: IVar, index: number) => {
									return (
										<div key={index} className="text-xs flex items-center space-x-2">
											{affectedVar.value < 0 && (
												<svg style={{ width: "20px" }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="orange">
													<path
														fillRule="evenodd"
														d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-11a1 1 0 10-2 0v3.586L7.707 9.293a1 1 0 00-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L11 10.586V7z"
														clipRule="evenodd"
													/>
												</svg>
											)}
											{affectedVar.value > 0 && (
												<svg style={{ width: "20px" }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="green">
													<path
														fillRule="evenodd"
														d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-8.707l-3-3a1 1 0 00-1.414 0l-3 3a1 1 0 001.414 1.414L9 9.414V13a1 1 0 102 0V9.414l1.293 1.293a1 1 0 001.414-1.414z"
														clipRule="evenodd"
													/>
												</svg>
											)}
											<span>{affectedVar.value}</span>
											<span className="font-bold">{affectedVar.alias}</span>
										</div>
									);
								})}
							</div>
						)}
						{effect.globalAffectedVariables && (
							<div className="mt-2 flex flex-col space-y-2">
								{effect.globalAffectedVariables.map((affectedVar: IVar, index: number) => {
									return (
										<div key={index} className="text-xs flex items-center space-x-2">
											{affectedVar.value < 0 && (
												<svg style={{ width: "20px" }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="orange">
													<path
														fillRule="evenodd"
														d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-11a1 1 0 10-2 0v3.586L7.707 9.293a1 1 0 00-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L11 10.586V7z"
														clipRule="evenodd"
													/>
												</svg>
											)}
											{affectedVar.value > 0 && (
												<svg style={{ width: "20px" }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="green">
													<path
														fillRule="evenodd"
														d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-8.707l-3-3a1 1 0 00-1.414 0l-3 3a1 1 0 001.414 1.414L9 9.414V13a1 1 0 102 0V9.414l1.293 1.293a1 1 0 001.414-1.414z"
														clipRule="evenodd"
													/>
												</svg>
											)}
											<span>{affectedVar.value}</span>
											<span className="font-bold">{affectedVar.alias}</span>
										</div>
									);
								})}
							</div>
						)}
					</div>
				);
			})}
			{showUseButton && (
				<PrimaryButton
					className="mt-4"
					onClick={async () => {
						const actorId = props.overrideActorId ?? props.defaultGame.actorId;
						if (props.useAction) {
							props.useAction(props.defaultGame.id, props.currentRound.roundId, actorId, props.action.id).then(() => {
								setUsedAt(format(new Date(), "dd/MM/yyyy hh:mm aa"));
							});
						}
					}}>
					Usar
				</PrimaryButton>
			)}
			{usedAt && <div className="mt-4 font-bold">Usada el {usedAt}</div>}
			<div className="py-4"></div>
		</ActionCardWrapper>
	);
}
interface IInfoCardProps {
	gameConfig: IGameConfig;
	actorId: string;
	currentRound?: IGameRound;
}
function InfoCard(props: IInfoCardProps) {
	return (
		<div className="text-black">
			<h2 className="text-xl font-bold">General Info</h2>
			<p className="text-sm">
				<strong>Game: </strong>
				{props.gameConfig.title}
			</p>
			<p className="text-sm">
				<strong>Game Status: </strong>
				{props.gameConfig.status !== "Started" ? "Configuring" : "Started"}
			</p>
			<p className="text-sm">
				<strong>Actor: </strong>
				{props.actorId}
			</p>
			{props.currentRound && (
				<>
					<p className="text-sm">
						<strong>Current Round: </strong>
						{props.currentRound.label}
					</p>
					<p className="text-sm">
						<strong>Round End Date: </strong>
						{format(new Date(props.currentRound.end), "dd/MM/yyyy hh:mm aa")}
					</p>
				</>
			)}
		</div>
	);
}

export default Game;
