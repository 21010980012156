import { IGameConfig, IGamerAccountGame } from "@/api";
import { PrimaryButton } from "@/components";
import React from "react";
import { GameNavBarAction, GameViews } from "./types";

interface Props {
	currentView: GameViews;
	setCurrentView: (newView: GameViews) => void;
	gameConfig: IGameConfig;
	defaultGame: IGamerAccountGame;
	onGameNavBarAction: (action: GameNavBarAction) => void;
}

export function GameNavBar({ currentView, setCurrentView, gameConfig, defaultGame, onGameNavBarAction }: Props) {
	const isMainView = currentView.startsWith("actions_actor");
	if (!isMainView) {
		return (
			<PrimaryButton
				onClick={() => {
					setCurrentView("actions_actor");
				}}>
				{`<- Regresar`}
			</PrimaryButton>
		);
	}
	// const [_, secondaryMainView] = currentView.split(":");
	return (
		<div>
			<div className="mt-4 flex flex-col md:flex-row justify-start space-x-0 md:space-x-4 space-y-4 md:space-y-0">
				<PrimaryButton
					onClick={() => {
						setCurrentView("game_info");
					}}>
					Información General
				</PrimaryButton>
				<PrimaryButton
					onClick={() => {
						setCurrentView("actions_affected");
					}}>
					{isMainView && <>Ver las acciones que te afectan</>}
					{/* {(currentView === "actions_affected" || currentView === "board" || currentView === "game_info" || currentView === "admin_actions_view") && <>{`<- Regresar`}</>} */}
				</PrimaryButton>
				{gameConfig && gameConfig.status === "Started" && <PrimaryButton onClick={() => onGameNavBarAction("show_used_actions")}>Acciones Realizadas</PrimaryButton>}

				{defaultGame && defaultGame.actorId === "Zazar" && <PrimaryButton onClick={() => onGameNavBarAction("zazar_show_all_actions")}>Todas las acciones</PrimaryButton>}

				<PrimaryButton as="a" href="https://docs.google.com/forms/d/e/1FAIpQLSdkGJz8pNHNOWvKVltMnCWCQXzbEQ6HQpOSq0YdFWdclgzEKQ/viewform" target="_blank">
					Diario de Aprendizaje
				</PrimaryButton>
			</div>
			{isMainView && (
				<div className="flex border-b border-gray-200 dark:border-gray-700 mt-4">
					<button
						onClick={() => setCurrentView("actions_actor:uni")}
						className={`h-10 px-4 py-2 -mb-px text-sm text-center ${currentView === "actions_actor:uni" || currentView === "actions_actor" ? "text-black" : "text-gray-700"} bg-transparent border-b-2 ${
							currentView === "actions_actor:uni" || currentView === "actions_actor" ? "border-black" : "border-transparent"
						} sm:text-base dark:border-blue-400 dark:text-blue-300 whitespace-nowrap focus:outline-none`}>
						Unilaterales
					</button>

					<button
						onClick={() => setCurrentView("actions_actor:multi")}
						className={`h-10 px-4 py-2 -mb-px text-sm text-center ${currentView === "actions_actor:multi" ? "text-black" : "text-gray-700"} bg-transparent border-b-2 ${
							currentView === "actions_actor:multi" ? "border-black" : "border-transparent"
						} sm:text-base dark:text-white whitespace-nowrap cursor-base focus:outline-none hover:border-gray-400`}>
						Multilaterales
					</button>
				</div>
			)}
		</div>
	);
}
